import React, { useRef, useState } from 'react';
import { Alert, Button, Col, Layout, Modal, Row, Tooltip, Upload } from 'antd';
import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import { connect } from 'react-redux';
import { LazyInput } from '../../../../../components/LazyInput/LazyInput';
import { getBase64 } from '../../../../../app/utils/imageUtils';

const TrailInformationsComponent = ({
  onNext,
  onFieldChange,
  saveButton,
  defaultValues,
}) => {
  const { Content } = Layout;

  const trailTitleRef = useRef(null);
  const trailDescriptionRef = useRef(null);
  const [ deleteModal, setDeleteModal ] = useState(false);

  const handleUploadChange = (info) => {
    if (info && info.file && info.file.originFileObj) {
      getBase64(info.file.originFileObj, (imageBase64) => {
        onFieldChange('iconImage', imageBase64);
      });
    }
  };

  const handleDelete = () => {
    onFieldChange('iconImage', undefined);
    setDeleteModal(false)
  }

  return (
    <Content className='panel__layout__content trail_step_container'>
      <Row align='middle'>
        <h3>
          <InfoCircleOutlined />
          {I18n.t('routes.panel.knowledgeTrails.create.informations.header')}
        </h3>
      </Row>

      <Col className='trail_step_content'>
        <Col className='trail_information_panel_container'>
          <h4>{I18n.t('routes.panel.knowledgeTrails.create.informations.fields.title')}</h4>
          <Row className='trail_information_panel_content'>
            <Col className='title_description_container'>
              <LazyInput
                handleSendValue={(val) => onFieldChange('title', val)}
                renderInput={(onChange) => (
                  <AdvancedInput
                    label={I18n.t('routes.panel.knowledgeTrails.create.informations.fields.input.title')}
                    ref={trailTitleRef}
                    onChange={onChange}
                    maxLength={30}
                    tooltip={I18n.t('routes.panel.knowledgeTrails.create.informations.fields.tooltip.title')}
                    defaultValue={defaultValues?.title || ''}
                  />
                )}
              />
              <LazyInput
                handleSendValue={(val) => onFieldChange('description', val)}
                renderInput={(onChange) => (
                  <AdvancedInput
                    label={I18n.t('routes.panel.knowledgeTrails.create.informations.fields.input.description')}
                    isTextarea
                    ref={trailDescriptionRef}
                    maxLength={150}
                    onChange={onChange}
                    defaultValue={defaultValues?.description || ''}
                    tooltip={I18n.t('routes.panel.knowledgeTrails.create.informations.fields.tooltip.description')}
                  />
                )}
              />
            </Col>
            <Col className='uploader_box_container'
            >
              {defaultValues?.iconImage ? (
                <div className='preview_box_container'>
                  <img
                    src={defaultValues.iconImage}
                    alt='Icon'
                  />
                  <div
                    className='delete_preview'
                    onClick={() => setDeleteModal(true)}
                  >
                    <DeleteOutlined />
                  </div>
                </div>
              ) : (
                <Upload
                  className='uploader'
                  showUploadList={false}
                  onChange={handleUploadChange}
                  listType='picture-card'
                >
                  <button className='upload_button' type="button">
                    <PlusOutlined />
                    <div>
                      <span>
                        {I18n.t('routes.panel.knowledgeTrails.create.informations.fields.input.upload')}
                        <Tooltip
                          title={I18n.t('routes.panel.knowledgeTrails.create.informations.fields.tooltip.upload')}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <InfoCircleOutlined style={{ marginLeft: 5, verticalAlign: 3 }} />
                        </Tooltip>
                      </span>
                    </div>
                  </button>
                </Upload>
              )}
            </Col>
          </Row>
        </Col>

        <Row justify='end'
          className='buttons_container'>
          <Button disabled={!saveButton.canSave}
            onClick={saveButton.onSave}>
            {saveButton.label}
          </Button>
          <Button type='primary'
            onClick={onNext}>
            {I18n.t('routes.panel.knowledgeTrails.create.button.goNext')}
          </Button>
        </Row>

        <Modal
          visible={deleteModal}
          onOk={handleDelete}
          onCancel={() => setDeleteModal(false)}
        >
          <p>
            {I18n.t('routes.panel.knowledgeTrails.create.contents.modal.deleteCover')}
          </p>
        </Modal>
      </Col>
    </Content>
  );
};

export const TrailInformations = connect(
  null,
)(TrailInformationsComponent);
