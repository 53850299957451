import { Button, Row } from 'antd'
import React, { useCallback } from 'react'
import { TRAIL_OPTION, TRAIL_OPTION_LABEL } from '../../../app/enum/trails'
import { EditOutlined, FileSearchOutlined, UndoOutlined } from '@ant-design/icons'
import { I18n } from 'react-redux-i18n'
import { youtubeRedirectBaseUrl } from '../../../app/utils/youtube'

export const TrailDefinedItem = ({handleEdit, definedItem, canUndo, handleUndo}) => {

  const getContentDetailsUrl = useCallback((id) => {
    switch (definedItem.type) {
      case TRAIL_OPTION.QUESTION:
        return `${I18n.t('routes.panel.quiz.urlQuestion')}${id}`
      case TRAIL_OPTION.AUDIO:
      case TRAIL_OPTION.VIDEO:
      case TRAIL_OPTION.RESUME:
        return `${I18n.t('routes.panel.contentDetails.url')}${id}`
      case TRAIL_OPTION.YOUTUBE:
        return `${youtubeRedirectBaseUrl}${id}`
      default:
        return async () => null
    }
  }, [ definedItem.type ])

  return (
    <Row className='trail_defined_item'>
      {definedItem.thumbnailUrl && (
        <img
          src={definedItem.thumbnailUrl}
          height={120}
        />
      )}
      <span>{TRAIL_OPTION_LABEL[definedItem.type]()}: {definedItem.title}</span>
      <div className='buttons_box'>
        {canUndo && (
          <Button
            type='link'
            icon={<UndoOutlined />}
            onClick={handleUndo}
          />
        )}
        <Button
          type='link'
          icon={<EditOutlined />}
          onClick={handleEdit}
        />
        <Button
          type='link'
          icon={<FileSearchOutlined />}
          onClick={() => window.open(getContentDetailsUrl(definedItem.videoId || definedItem.id), '_blank').focus()}
        />
      </div>
    </Row>
  )
}