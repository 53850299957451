import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

export const TRAIL_OPTION = {
  YOUTUBE: 1,  // External
  VIDEO: 2,    // Content
  AUDIO: 3,    // Content
  RESUME: 4,   // Content
  QUESTION: 5, // Question
  EXAM: 6,     // Quiz
};

export const VERTICAL_FILTERS_FIELD_TYPE = {
  SELECT: 1,
  TEXT: 2,
}

export const TrailEnableType = {
  ENABLE: 2,
  DESABLE: 1,
};

export const TrailIsEnableEnum = [
  { id: TrailEnableType.ENABLE, name: 'Disponível' },
  { id: TrailEnableType.DESABLE, name: 'Indisponível' },
];

export const TrailDifficultyType = {
  EASY: 1,
  MEDIUM: 2,
  HARD: 3,
};

export const TrailDifficultyTypeArray = [
  {
    id: TrailDifficultyType.EASY,
    name: <Translate value='forms.newQuestion.labelQuestionEasy' />,
  },
  {
    id: TrailDifficultyType.MEDIUM,
    name: <Translate value='forms.newQuestion.labelQuestionMedium' />,
  },
  {
    id: TrailDifficultyType.HARD,
    name: <Translate value='forms.newQuestion.labelQuestionHard' />,
  },
];

export const QuestionType = {
  MULTIPLE_ANSWER: 1,
  DISSERTATION: 2,
  OTHERS: 3,
};

export const QuestionTypeArray = [
  {
    id: QuestionType.MULTIPLE_ANSWER,
    name: <Translate value='forms.newQuestion.labelMultipleAnswerType' />,
  },
  {
    id: QuestionType.DISSERTATION,
    name: <Translate value='forms.newQuestion.labelDissertationType' />,
  }
];

export const TRAIL_OPTION_LABEL = {
  [ TRAIL_OPTION.YOUTUBE ]: () =>
    I18n.t('routes.panel.knowledgeTrails.create.contents.types.youtube'),
  [ TRAIL_OPTION.VIDEO ]: () =>
    I18n.t('routes.panel.knowledgeTrails.create.contents.types.video'),
  [ TRAIL_OPTION.AUDIO ]: () =>
    I18n.t('routes.panel.knowledgeTrails.create.contents.types.audio'),
  [ TRAIL_OPTION.RESUME ]: () =>
    I18n.t('routes.panel.knowledgeTrails.create.contents.types.resumo'),
  [ TRAIL_OPTION.QUESTION ]: () =>
    I18n.t('routes.panel.knowledgeTrails.create.contents.types.question'),
  [ TRAIL_OPTION.EXAM ]: () =>
    I18n.t('routes.panel.knowledgeTrails.create.contents.types.exam'),
};

const descriptionFilter = {
  fieldKey: 'description',
  label: <Translate value='shared.advancedFilters.form.questionDescription' />,
  type: VERTICAL_FILTERS_FIELD_TYPE.TEXT,
};

const categoryFilter = {
  fieldKey: 'categoryId',
  label: <Translate value='shared.advancedFilters.form.category' />,
  type: VERTICAL_FILTERS_FIELD_TYPE.SELECT,
  listFromOtherPlaceKey: 'categories',
  photoUrl: true
};

const categoryFilterToQuestions = {
  fieldKey: 'categoriesId',
  label: <Translate value='shared.advancedFilters.form.category' />,
  type: VERTICAL_FILTERS_FIELD_TYPE.SELECT,
  listFromOtherPlaceKey: 'categories',
  photoUrl: true
};

const yearFilter = {
  fieldKey: 'year',
  label: <Translate value='shared.advancedFilters.form.year' />,
  type: VERTICAL_FILTERS_FIELD_TYPE.TEXT,
};

const difficultyFilter = {
  fieldKey: 'difficulty',
  label: <Translate value='shared.advancedFilters.form.difficulty' />,
  type: VERTICAL_FILTERS_FIELD_TYPE.SELECT,
  selectOptions: TrailDifficultyTypeArray,
};

const questionTypeFilter = {
  fieldKey: 'type',
  label: <Translate value='shared.advancedFilters.form.type' />,
  type: VERTICAL_FILTERS_FIELD_TYPE.SELECT,
  selectOptions: QuestionTypeArray,
};

const titleFilter = {
  fieldKey: 'title',
  label: <Translate value='shared.advancedFilters.form.contentName' />,
  type: VERTICAL_FILTERS_FIELD_TYPE.TEXT,
};

const nameFilter = {
  fieldKey: 'name',
  label: <Translate value='shared.advancedFilters.form.contentName' />,
  type: VERTICAL_FILTERS_FIELD_TYPE.TEXT,
};

const companyFilter = {
  fieldKey: 'companyId',
  label: <Translate value='shared.advancedFilters.form.company' />,
  type: VERTICAL_FILTERS_FIELD_TYPE.SELECT,
  listFromOtherPlaceKey: 'companies',
};

export const questionsFilters = [
  descriptionFilter,
  categoryFilterToQuestions,
  questionTypeFilter,
  difficultyFilter,
  yearFilter
];

export const contentFilters = [
  titleFilter,
  categoryFilter,
  companyFilter,
];

export const examFilters = [ nameFilter, categoryFilterToQuestions ];

export const ContentMediaType = {
  AUDIO: 1,
  VIDEO: 2,
  RESUME: 3,
};

export const TrailTemplate = {
  CUSTOM: 1,
  BASIC: 2,
};

export const trailsTemplatesSelectableList = [
  {
    name: (
      <Translate value='routes.panel.knowledgeTrails.create.contents.teplates.custom' />
    ),
    id: TrailTemplate.CUSTOM,
  },
  {
    name: (
      <Translate value='routes.panel.knowledgeTrails.create.contents.teplates.basic' />
    ),
    id: TrailTemplate.BASIC,
  },
];

const basicTrailTemplate = [
  {
    id: null,
    fakeId: Math.random().toString(),
    content: {
      type: TRAIL_OPTION.YOUTUBE,
    },
    newContent: null,
  },
  {
    id: null,
    fakeId: Math.random().toString(),
    content: {
      type: TRAIL_OPTION.VIDEO,
    },
    newContent: null,
  },
  {
    id: null,
    fakeId: Math.random().toString(),
    content: {
      type: TRAIL_OPTION.AUDIO,
    },
    newContent: null,
  },
  {
    id: null,
    fakeId: Math.random().toString(),
    content: {
      type: TRAIL_OPTION.RESUME,
    },
    newContent: null,
  },
  {
    id: null,
    fakeId: Math.random().toString(),
    content: {
      type: TRAIL_OPTION.QUESTION,
    },
    newContent: null,
  },
  {
    id: null,
    fakeId: Math.random().toString(),
    content: {
      type: TRAIL_OPTION.EXAM,
    },
    newContent: null,
  },
];

export const trailsTemplates = {
  [ TrailTemplate.BASIC ]: basicTrailTemplate,
};
