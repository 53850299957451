import React, { useState } from 'react';
import { Breadcrumb, Col, Layout, Steps } from 'antd';
import { I18n } from 'react-redux-i18n';
import { HiddenControll } from '../../../../../components/HiddenControll/HiddenControll';
import { PublishTrail } from '../PublishTrail/PublishTrail';
import { TrailInformations } from '../TrailInformations/TrailInformations';
import { TrailContentComposition } from '../TrailContentComposition/TrailContentComposition';
import { StudentAssignment } from '../StudentAssignment/StudentAssignment';
import Loading from '../../../../../components/shared/Loading/Loading';
import { useSelector } from 'react-redux';
import { LoadingSelectors } from '../../../../../app/redux/reducers';
import CompanyTrail from '../CompanyTrail/CompanyTrail';
import { Link } from '@reach/router';


const { Step } = Steps;
const { Content } = Layout;

const trailStepsLabels = [
  'Informações',
  'Composição de conteúdo',
  'Atribuição de alunos',
  'Atribuição de empresas',
  'Publicação',
];

const TrailForm = ({
  handleFieldChange,
  trailFields,
  handleConclude,
  routeTitle,
  saveButton,
  setSelectedCompanyId,
  selectedCompanyId
}) => {
  const [ currentStep, setCurrentStep ] = useState(0);
  const loading = useSelector(LoadingSelectors.getLoading);

  return (
    <div>
      {!!loading && <Loading />}
      <Content className='panel__layout__content panel__layout__content--breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`${I18n.t('routes.panel.knowledgeTrails.url')}`}>
              <span>{I18n.t('routes.panel.knowledgeTrails.sidebarTitle')}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{routeTitle}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Content className='panel__layout__content'>
        <Col style={{ marginBottom: 20 }}>
          <h4>{I18n.t('routes.panel.knowledgeTrails.create.header')}</h4>
        </Col>

        <Steps current={currentStep}>
          {trailStepsLabels.map((step, index) => (
            <Step
              title={step}
              key={index}
              onStepClick={(stepIndex) => setCurrentStep(stepIndex)}
            />
          ))}
        </Steps>
      </Content>
      <HiddenControll
        currentVisible={currentStep}
        items={[
          <TrailInformations
            key='trailInformations'
            saveButton={saveButton}
            onNext={() => setCurrentStep(1)}
            onFieldChange={handleFieldChange}
            defaultValues={trailFields}
          />,
          <TrailContentComposition
            key='trailContentComposition'
            saveButton={saveButton}
            onNext={() => setCurrentStep(2)}
            onChange={handleFieldChange}
            fields={trailFields}
          />,
          <StudentAssignment
            key='studentAssignment'
            saveButton={saveButton}
            onNext={() => setCurrentStep(3)}
            onChange={handleFieldChange}
            defaultStudentsList={trailFields.users || []}
          />,
          <CompanyTrail
            key='companyTrail'
            onNext={() => setCurrentStep(4)}
            loading={loading}
            saveButton={saveButton}
            onChange={handleFieldChange}
            selectedCompanyId={selectedCompanyId}
            setSelectedCompanyId={setSelectedCompanyId}
            defaultCompany={trailFields.company || []}
          />,
          <PublishTrail
            key='publishTrail'
            onConclude={handleConclude}
            saveButton={saveButton}
            onFieldChange={handleFieldChange}
            fields={trailFields}
          />,
        ]}
      />
    </div>
  );
};

export default TrailForm;
