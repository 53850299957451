import { Col } from 'antd';
import React, { useState } from 'react';
import AdvancedSelect from '../shared/AdvancedSelect/AdvancedSelect';
import AdvancedButton from '../shared/AdvancedButton/AdvancedButton';
import { I18n } from 'react-redux-i18n';
import { SearchOutlined } from '@ant-design/icons';
import AdvancedInput from '../shared/AdvancedInput/AdvancedInput';
import { useSelector } from 'react-redux';
import { CategorySelectors, CompanySelectors } from '../../app/redux/reducers';
import { VERTICAL_FILTERS_FIELD_TYPE } from '../../app/enum/trails';

/**
 * @param {Object} props
 * @param {String} props.headerLabel
 * @param {Object[]} props.fields
 * @param {String} props.fields.label
 * @param {String} props.fields.fieldKey
 * @param {Number} props.fields.type
 * @param {Object[]} props.fields.selectOptions
 * @param {String} props.fields.selectOptions.id
 * @param {String} props.fields.selectOptions.name
 * @param {Function} props.onFilter
 */
export const VerticalFiltersPanel = ({ headerLabel, fields, onFilter, onClear }) => {
  const [ fieldsValues, setFieldsValues ] = useState({});

  const companySelectives = useSelector(CompanySelectors.getCompanySelectives)
  const categoriesSelectives = useSelector(CategorySelectors.getCategorySelectives)

  const listsFromRedux = {
    companies: companySelectives,
    categories: categoriesSelectives
  }

  const handleSetFieldValues = (fieldKey, fieldValue) => {
    setFieldsValues(prev => {
      const newObj = { ...prev }
      newObj[ fieldKey ] = fieldValue
      return newObj;
    })
  }

  const handleFilerButtonClick = () => {
    onFilter(fieldsValues)
  }

  const handleClearFilters = () => {
    setFieldsValues({})
    onClear()
  }

  return (
    <Col className='vertical_filters_panel_container'>
      <h5>{headerLabel}</h5>
      {
        fields.map(fielItem => {
          switch (fielItem.type) {
            case VERTICAL_FILTERS_FIELD_TYPE.SELECT:
              return (
                <AdvancedSelect
                  options={listsFromRedux[ fielItem.listFromOtherPlaceKey ] || fielItem.selectOptions}
                  label={fielItem.label}
                  value={fieldsValues[ fielItem.fieldKey ]}
                  onChange={(val) => handleSetFieldValues(fielItem.fieldKey, val)}
                  colorDots
                />
              );
            default:
              return (
                <AdvancedInput
                  value={fieldsValues[ fielItem.fieldKey ]}
                  onChange={(val) => handleSetFieldValues(fielItem.fieldKey, val)}
                  label={fielItem.label}
                  onPressEnter={handleFilerButtonClick}
                />
              )
          }
        })
      }
      <Col
        span={24}
        className='advanced-filter__search-button text-right'
      >
        <AdvancedButton
          type='link'
          text={I18n.t('shared.advancedFilters.clearButtonText')}
          onClick={handleClearFilters}
        />
        <AdvancedButton
          onClick={handleFilerButtonClick}
          text={I18n.t('shared.filter')}
          icon={<SearchOutlined />}
        />
      </Col>
    </Col>
  );
}
