import { useEffect, useState } from 'react';
import CompanyApi from '../../api/company';
import { message } from 'antd';
import { I18n } from 'react-redux-i18n';

export const useCompaniesSelect = ({ defaultCompaniesList = [] }) => {
  const [ companiesList, setCompaniesList ] = useState(defaultCompaniesList);
  const [ loadingCompanies, setLoadingCompanies ] = useState(false);

  const findCompanies = async () => {
    if (loadingCompanies) return;

    setLoadingCompanies(true);

    try {
      const companies = await CompanyApi.getCompaniesPaginated({ limit: 1000 })
      setCompaniesList(companies?.rows || []);
    } catch (err) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.companyAssignment.error'));
    } finally {
      setLoadingCompanies(false)
    }

  };

  useEffect(() => {
    findCompanies()
  }, []);

  return { companiesList, loadingCompanies }
};