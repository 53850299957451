import React from 'react';
import { message, Progress, Upload } from 'antd';
import { I18n } from 'react-redux-i18n';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';

import { ReactComponent as VideoDownload } from '../../../app/assets/img/video_download.svg';
import { ReactComponent as AudioDownload } from '../../../app/assets/img/audio_download.svg';
import { ReactComponent as PdfDownload } from '../../../app/assets/img/pdf_download.svg';
import { ReactComponent as ImageDownload } from '../../../app/assets/img/image_download.svg';

const { Dragger } = Upload;

const fileLimitInMegabytes = 300;

const AdvancedDragger = ({
  multiple,
  customRequest,
  accept,
  fileList,
  onRemove,
  title,
  label,
  progress,
  progressIcon,
  deletedOnClick,
}) => {

  const beforeUpload = (file) => {
    if (file.size / 1024 / 1024 > fileLimitInMegabytes) {
      message.error(I18n.t('forms.content.fileLarge', { fileLimitInMegabytes }));
      return false
    }

    return true;
  };

  return (
    <div>
      <div>{title}</div>
      <Dragger
        name='file'
        multiple={multiple || false}
        customRequest={customRequest}
        accept={accept}
        fileList={fileList || []}
        onRemove={onRemove}
        beforeUpload={beforeUpload}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>{I18n.t('shared.uploadFile.addFiles')}</p>
        <p className='ant-upload-hint'>{label}</p>
      </Dragger>
      {progress > 0 ? (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
          {progressIcon === 'pdf' ?? (
            <PdfDownload style={{ marginRight: 10 }}
              stroke='#0084CF' />
          )}
          {progressIcon === 'audio' ?? (
            <AudioDownload style={{ marginRight: 10 }}
              stroke='#0084CF' />
          )}
          {progressIcon === 'video' ?? (
            <VideoDownload style={{ marginRight: 10 }}
              stroke='#0084CF' />
          )}
          {progressIcon === 'image' ?? (
            <ImageDownload style={{ marginRight: 10 }}
              stroke='#0084CF' />
          )}

          <Progress percent={progress} />
          <DeleteOutlined onClick={deletedOnClick} />
        </div>
      ) : null}
    </div>
  )
};

export default AdvancedDragger;
