/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import {
  trailContentsKey,
  trailTemplateTypeKey,
} from './TrailContentComposition/TrailContentComposition';
import { useDispatch } from 'react-redux';

import TrailsApi from '../../../../app/api/trails';
import { trailsTemplates, TrailTemplate } from '../../../../app/enum/trails';
import { CategoryActions, CompanyActions } from '../../../../app/redux/actions';
import TrailForm from '../../../../components/TrailForm/TrailForm';
import { message } from 'antd';
import { navigate } from '@reach/router';
import { addLoading, removeLoading } from '../../../../app/redux/actions/loading';


export const CreateTrail = () => {
  const referenceTypeEnum = {
    1: 'EXTERNAL',
    2: 'VIDEO',
    3: 'AUDIO',
    4: 'RESUME',
    5: 'QUESTION',
    6: 'QUIZ',
  };
  const [ selectedCompanyId, setSelectedCompanyId ] = useState(null);
  const dispatch = useDispatch();

  const [ trailFields, setTrailFields ] = useState({
    [ trailTemplateTypeKey ]: TrailTemplate.BASIC,
    [ trailContentsKey ]: trailsTemplates[ TrailTemplate.BASIC ],
    companyId: selectedCompanyId,
  });
  const [ isMounted, setIsMounted ] = useState(false);
  const [ hasPendingDataToSave, setHasPendingDataToSave ] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      dispatch(CompanyActions.getCompanySelectives());
      dispatch(CategoryActions.getCategorySelectives());
      setIsMounted(true);
      return;
    }

    setHasPendingDataToSave(true);
  }, [ trailFields ]);

  useEffect(() => {
    setTrailFields((prevFields) => ({
      ...prevFields,
      companyId: selectedCompanyId,
    }));
  }, [ selectedCompanyId ]);

  const handleFieldChange = (fieldKey, fieldValue) => {
    setTrailFields((prevFields) => ({
      ...prevFields,
      [ fieldKey ]: fieldValue,
    }));
  };

  const validatingForm = (form) => {
    if (!form.title) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.title'));
      return false;
    }

    if (!form.coverUrl) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.coverUrl'));
      return false;
    }

    if (!form.description) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.description'));
      return false;
    }

    if (!form.companyId) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.companyId'));
      return false;
    }

    if (form.steps.length === 0) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.steps'));
      return false;
    }

    const isInvalidContent = form.steps.find(step => step.referenceType !== 'EXTERNAL' && !step.referenceId)
    const isInvalidYouTube = form.steps.find(step => step.referenceType === 'EXTERNAL' && !step.url)

    if (isInvalidContent || isInvalidYouTube) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.incompleteSteps'));
      return false;
    }

    if (form.students.length === 0) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.students'));
      return false;
    }

    return true;
  };

  const validatingFormToDraft = (form) => {
    const isInvalidContent = form.steps.find(step => step.referenceType !== 'EXTERNAL' && !step.referenceId)
    const isInvalidYouTube = form.steps.find(step => step.referenceType === 'EXTERNAL' && !step.url)

    if (isInvalidContent || isInvalidYouTube) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.incompleteSteps'));
      return false;
    }

    return true;
  };

  const handleConclude = async (isDraft) => {
    dispatch(addLoading());

    const dataToSave = {
      title: trailFields?.title || '',
      companyId: trailFields?.companyId || null,
      coverUrl: trailFields?.iconImage || '',
      description: trailFields?.description || '',
      isEnabled: false,
      isDraft,
      startAt: trailFields.startAt ? trailFields.startAt : undefined,
      steps: trailFields?.contents?.length > 0
        ? trailFields?.contents.map((item, index) => {
          const contentToUse = item.newContent || item.content;

          return {
            isAvailable: false,
            referenceType: referenceTypeEnum[ contentToUse?.type ] || contentToUse?.type,
            referenceId: contentToUse?.id,
            order: index + 1,
            title: contentToUse?.title,
            url: contentToUse?.url,
            thumbnail_url: contentToUse?.thumbnailUrl,
          };
        })
        : [],
      students: trailFields?.students?.length > 0 ? trailFields?.students.map((student) => ({
        id: student.id,
      })) : []
    };

    let isValid = false

    if (isDraft) {
      isValid = validatingFormToDraft(dataToSave)
    } else {
      isValid = validatingForm(dataToSave)
    }

    try {
      if (isValid) {
        await TrailsApi.postTrail(dataToSave);
        message.success(I18n.t('routes.panel.knowledgeTrails.create.success'));
        navigate(I18n.t('routes.panel.knowledgeTrails.url'))
      }
    } catch (error) {
      message.error(I18n.t('routes.panel.knowledgeTrails.create.error.generic'));
    } finally {
      dispatch(removeLoading());
    }
  };

  const saveButton = {
    canSave: hasPendingDataToSave,
    label: I18n.t('shared.saveDraft'),
    canConclude: true,
    onSave: () => handleConclude(true),
  };

  return (
    <TrailForm
      handleFieldChange={handleFieldChange}
      saveButton={saveButton}
      trailFields={trailFields}
      handleConclude={() => handleConclude(false)}
      routeTitle={I18n.t('routes.panel.knowledgeTrails.create.pageTitle')}
      selectedCompanyId={selectedCompanyId}
      setSelectedCompanyId={setSelectedCompanyId}
    />
  );
};
