import { useEffect, useMemo, useState } from 'react';

export const useUserAssignmentToTrails = ({
  usersPaginated,
  onChangeUsersList,
  defaultStudentsList,
}) => {
  const [ selectedUsers, setSelectedUsers ] = useState([]);

  useEffect(() => {
    if(defaultStudentsList && defaultStudentsList.length > 0) {
      setSelectedUsers(defaultStudentsList);
    }
  }, [ defaultStudentsList ])

  const insertOrRemoveUser = (user, isChecked) => {
    setSelectedUsers((prevList) => {
      let newList;

      if (isChecked) {
        newList = prevList.concat(user);
      } else {
        newList = prevList.filter((userItem) => userItem.id !== user.id);
      }

      if (onChangeUsersList) onChangeUsersList(newList);

      return newList;
    });
  };

  const usersToShowInTable = useMemo(() => {

    return {
      count: usersPaginated?.count || [],
      rows:
        usersPaginated?.rows.map((user) => ({
          ...user,
          selected: selectedUsers.some(
            (selectedUser) => selectedUser.id === user.id,
          ),
          course: '-',
        })) || [],
    };
  }, [ selectedUsers, usersPaginated ]);


  return {  usersToShowInTable, insertOrRemoveUser, selectedUsers };
};
