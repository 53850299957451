import { ProfileType } from '../../enum/profileType';
import { cleanPagination } from '../../utils/pagination';

export const useStudentsAssignmentTableData = ({
  getUsersFn, saveFiltersFn
}) => {
  const getUsers = async (fields) => {
    const paramsToUse = {
      ...fields,
      company: null,
      course: null,
      type: ProfileType.USER
    }

    await getUsersFn(paramsToUse)
    saveFiltersFn(fields)
  }

  const applyAdvancedFilters = (fields) => {
    const filtersToApply = {
      ...fields,
      ...cleanPagination,
      companyId: fields.company?.id,
      courseId: fields.course?.id,
    }

    getUsers(filtersToApply)
  };

  const cleanAdvancedFilters = () => {
    getUsers(cleanPagination)
  };

  const handleGetData = (params) => {
    getUsers(params)
  }

  return {
    applyAdvancedFilters, cleanAdvancedFilters, handleGetData
  }
};