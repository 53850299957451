import React from 'react'

import { useCoursesSelect } from '../../../app/hooks/useCoursesSelect';
import SearchableInput from '../../SearchableInput/SearchableInput'

export const CourseSearchableInput = ({selectedCompany, setSelectedCourse, selectedCourse}) => {
  const {
    coursesList, loadingCourses, onSearchCourses, loadMoreCourses
  } = useCoursesSelect({
    companyIdToFilter: selectedCompany,
    defaultCoursesList: selectedCourse ? {count: 0, rows: [ selectedCourse ]} : undefined
  });

  const handleSelectCourse = (selectedCourseId) => {
    const courseSelected = coursesList?.rows?.find(courseItem => courseItem.id === selectedCourseId)
    setSelectedCourse(courseSelected)
  }

  return (
    <SearchableInput
      data={coursesList.rows}
      title={'Curso'}
      value={selectedCourse?.id}
      loading={loadingCourses}
      placeholder={'Selecione o curso...'}
      onChange={handleSelectCourse}
      onSearch={onSearchCourses}
      onEndReached={loadMoreCourses}
      multiple={false}
    />
  )
}