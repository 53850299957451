import React from 'react';
import AdvancedSelect from '../AdvancedSelect/AdvancedSelect';
import { useCompaniesSelect } from '../../../app/hooks/trails/useCompaniesSelect';


export const CompanyAdvancedSelect = ({ selectedCompany, setSelectedCompany }) => {
  const {
    companiesList, loadingCompanies,
  } = useCompaniesSelect({
    defaultCompaniesList: selectedCompany ? [ selectedCompany ] : undefined,
  });

  const handleSelectedValue = (selectedCompanyId) => {
    const selectedCompany = companiesList.find(company => company.id === selectedCompanyId)

    setSelectedCompany(selectedCompany)
  };

  return (
    <AdvancedSelect
      loading={loadingCompanies}
      options={companiesList}
      label={'Empresa'}
      value={selectedCompany?.id}
      onChange={handleSelectedValue}
    />
  )
}