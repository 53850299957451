import { RocketOutlined } from '@ant-design/icons';
import { Alert, Button, Col, DatePicker, Layout, Row } from 'antd';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { TrailPreview } from '../../../../../components/TrailPreview/TrailPreview';
import moment from 'moment-timezone';
import ccdLogo from '../../../../../app/assets/img/ic_logo.svg';

const { Content } = Layout;

export const PublishTrail = ({
  onConclude,
  onFieldChange,
  fields,
  saveButton,
}) => {
  return (
    <Content className='panel__layout__content trail_step_container'>
      <Row align='middle'>
        <h3>
          <RocketOutlined />
          {I18n.t('routes.panel.knowledgeTrails.create.publish.header')}
        </h3>
      </Row>

      <Row className='trail_step_content'>
        <Col className='trail_publish_panel_container'>
          <h4>{I18n.t('routes.panel.knowledgeTrails.create.publish.scheduleTitle')}</h4>
          <Row className='date_fields_container'>
            <Col className='date_field'>
              <div>{I18n.t('routes.panel.knowledgeTrails.create.publish.dateAndHour')}</div>
              <DatePicker
                disabledDate={false}
                disabledTime={false}
                value={fields.startAt}
                onChange={(val) => onFieldChange('startAt', val)}
                format='DD/MM/YYYY HH:mm'
                className='date_picker'
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm') }}
              />
            </Col>
          </Row>
          <Alert
            message={I18n.t('routes.panel.knowledgeTrails.create.publish.warning.title')}
            description={I18n.t('routes.panel.knowledgeTrails.create.publish.warning.description')}
            type="info"
            showIcon
          />
        </Col>

        <Col className='trail_publish_panel_container'>
          <h4>{I18n.t('routes.panel.knowledgeTrails.create.publish.previewTitle')}</h4>
          <TrailPreview
            imageUri={
              fields?.imageUrl ||
              fields?.iconImage ||
              ccdLogo
            }
            title={fields?.title || '--'}
            description={fields.description || '--'}
          />
        </Col>
      </Row>

      <Row justify='end'
        className='buttons_container'>
        <Button disabled={!saveButton.canSave}
          onClick={saveButton.onSave}>
          {saveButton.label}
        </Button>
        {saveButton.canConclude && (
          <Button
            type='primary'
            disabled={!saveButton.canSave}
            onClick={onConclude}
          >
            {I18n.t('routes.panel.knowledgeTrails.update.button.save')}
          </Button>
        )}
      </Row>
    </Content>
  );
};
