import React, { useEffect, useMemo, useState } from 'react';
import { Button, Collapse, Layout, Row, Switch } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import DataTable from '../../../../../components/shared/DataTable/DataTable';
import { connect } from 'react-redux';
import {
  CompanySelectors,
  LoadingSelectors,
} from '../../../../../app/redux/reducers';
import { CompanyActions } from '../../../../../app/redux/actions';
import { CompanyFilterForm } from './CompanyTrailFilterForm';
import { I18n } from 'react-redux-i18n';

const { Content } = Layout;
const { Panel } = Collapse;

const CompanyTrail = ({
  loading,
  getCompanies,
  companies,
  onNext,
  saveButton,
  defaultCompany,
  selectedCompanyId,
  setSelectedCompanyId
}) => {
  const [ companyData, setCompanyData ] = useState([]);
  const [ paginationParams, setPaginationParams ] = useState({ current: 1, pageSize: 10 });

  useEffect(() => {
    getCompanies(paginationParams);
  }, [ getCompanies, paginationParams ]);

  useEffect(() => {
    setCompanyData(companies);
  }, [ companies ]);

  const sortedCompanies = companyData?.sort((a, b) => a.name.localeCompare(b.name)) || [];
  const companyToShowInTable = sortedCompanies?.map((company) => ({
    ...company,
    isActive: selectedCompanyId ? company.isActive : company.id === defaultCompany.id,
  }));

  const handleFiltersSubmit = (filters) => {
    const company = companies.find(company => company.id === filters.company.id)
    setCompanyData(company ? [ {...company, isActive: company.id === selectedCompanyId } ] : [])
  };

  const handleFiltersReset = () => {
    setCompanyData(companies.map((company) =>
      (company.id === selectedCompanyId
        ? { ...company, isActive: true }
        : { ...company, isActive: false }
      )
    ));
  };

  const handleSwitchChange = (companyId, checked) => {
    setSelectedCompanyId(checked ? companyId : undefined);
    setCompanyData((prevData) =>
      prevData.map((company) =>
        (company.id === companyId
          ? { ...company, isActive: checked }
          : { ...company, isActive: false })
      )
    );
  };

  const handleTableChange = (pagination) => {
    setPaginationParams({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });

    getCompanies({
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  return (
    <div>
      <Content className='panel__layout__content trail_step_container'>
        <Row align='middle'
          className='step_indicator_container'>
          <h3>
            <UsergroupAddOutlined />
            {I18n.t(
              'routes.panel.knowledgeTrails.create.companyAssignment.header',
            )}
          </h3>
        </Row>
      </Content>

      <Content className='panel__layout__content panel__layout__content--advanced-filter'>
        <Collapse className='advanced-filter'>
          <Panel header={I18n.t('shared.advancedFilters.title')}
            key='1'>
            <CompanyFilterForm
              onSubmit={handleFiltersSubmit}
              defaultFilters={{ company: companyData?.length === 1 ? companyData : null }}
              reset={handleFiltersReset}
            />
          </Panel>
        </Collapse>
      </Content>

      <Content className='panel__layout__content trail_step_container'>
        <DataTable
          getMethod={getCompanies}
          data={{ rows: companyToShowInTable, count: companyToShowInTable.length }}
          loading={loading > 0}
          showSizeChanger
          params={paginationParams}
          onChange={handleTableChange}
          columns={[
            {
              key: I18n.t(
                'routes.panel.knowledgeTrails.create.companyAssignment.dataTable.columns.companies.key',
              ),
              title: I18n.t(
                'routes.panel.knowledgeTrails.create.companyAssignment.dataTable.columns.companies.title',
              ),
              render: (text) => (text ? text : 'N/A'),
            },
            {
              key: I18n.t(
                'routes.panel.knowledgeTrails.create.companyAssignment.dataTable.columns.selected.key',
              ),
              title: I18n.t(
                'routes.panel.knowledgeTrails.create.companyAssignment.dataTable.columns.selected.title',
              ),
              render: (text, record) => (
                <Switch
                  checked={selectedCompanyId !== null ? selectedCompanyId === record.id : !!record.isActive}
                  onChange={(checked) => handleSwitchChange(record.id, checked)}
                />
              ),
            },
          ]}
        />

        <Row justify='end'
          className='buttons_container'>
          <Button disabled={!saveButton.canSave}
            onClick={saveButton.onSave}>
            {saveButton.label}
          </Button>
          <Button type='primary'
            onClick={onNext}>
            {I18n.t('routes.panel.knowledgeTrails.create.button.goNext')}
          </Button>
        </Row>
      </Content>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  companies: CompanySelectors.getCompanySelectives(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCompanies: (params) => dispatch(CompanyActions.getCompanySelectives(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTrail);
