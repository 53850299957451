import React from 'react';
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Divider,
  Layout,
  message,
  Popconfirm,
  Row,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import { CSVLink } from 'react-csv';
import DataTable from '../../../components/shared/DataTable';
import {
  AuthActions,
  FilterActions,
  TrailsActions,
  UtilsActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  FilterSelector,
  LoadingSelectors,
  TrailsSelectores,
} from '../../../app/redux/reducers';
import { ReactComponent as ModuleIcon } from '../../../app/assets/img/course_icon_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { ProfileType } from '../../../app/enum/profileType';
import { cleanPagination } from '../../../app/utils/pagination';
import { generateAndDownloadPdf } from '../../../app/utils/pdf/tablePdf';
import { prepareDataToCsv } from '../../../app/utils/csv';
import TrailsApi from '../../../app/api/trails';
import * as DateUtils from '../../../app/utils/date';

const { Panel } = Collapse;

class Trails extends React.Component {
  constructor (props) {
    super(props);
    const { trailsFilter } = props;
    const { trailsListFilter } = trailsFilter;

    this.state = {
      exportingDataLoading: false,
      exportingPfdDataLoading: false,
      csvData: '',
      optionsPdf: {
        orientation: 'landscape',
      },
      params: {
        ...cleanPagination,
        ...trailsListFilter,
      },
      company: '',
      companyList: [],
    };
    this.pdfLink = React.createRef();
    this.csvLink = React.createRef();
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    this.props.getCompanies((data) => {
      this.setState({ companyList: data });
    });
  }

  csvExport = async (exportAllDataFromApi) => {
    this.setState({ exportingDataLoading: true });

    const preparedDataToCsv = await prepareDataToCsv(
      this.props.trailsPaginated?.rows,
      (row) => ({
        title: row.title,
        company: row.company.name,
        studentsAmount: row.studentsAmount,
      }),
      exportAllDataFromApi,
      this.props.trailsPaginated?.count,
      TrailsApi.getTrailsPaginated,
      this.state.params,
    );

    this.setState({ exportingDataLoading: false });

    if (preparedDataToCsv) {
      this.setState({ csvData: preparedDataToCsv }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      });
    } else {
      message.error(I18n.t('shared.awaitData'));
    }
  };

  exportPdfData = async (exportAllDataFromApi) => {
    this.setState({ exportingPfdDataLoading: true });

    const tableHeadersToPrint = [
      I18n.t('routes.panel.knowledgeTrails.dataTable.columns.title.title'),
      I18n.t('routes.panel.knowledgeTrails.dataTable.columns.company.title'),
      I18n.t(
        'routes.panel.knowledgeTrails.dataTable.columns.studentsAmount.title',
      ),
    ];

    const generated = await generateAndDownloadPdf(
      this.props.trailsPaginated.rows,
      tableHeadersToPrint,
      I18n.t('routes.panel.knowledgeTrails.sidebarTitle'),
      12,
      12,
      (data) => [
        data.title || '--',
        data.company?.name || '--',
        data.studentsAmount || '--',
      ],
      exportAllDataFromApi,
      this.props.trailsPaginated?.count,
      TrailsApi.getTrailsPaginated,
      this.state.params,
      I18n.t('routes.panel.knowledgeTrails.sidebarTitle'),
    );

    this.setState({ exportingPfdDataLoading: false });

    if (!generated) {
      message.error(I18n.t('shared.awaitData'));
    }
  };

  getTrails = (params, isFromDataTable, isReset) => {
    const { getTrailsPaginated, trailsFilter } = this.props;
    const { trailsListFilter } = trailsFilter;

    if (!isFromDataTable) {
      this.setState({
        params: {
          ...this.state.params,
          ...params,
        },
      });
    }

    if (!isReset) {
      params = {
        ...trailsListFilter,
        ...params,
      };
    }

    this.setState({ params });
    getTrailsPaginated(params);
  };

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[ name ] = value ? value.toString() : null;
    this.setState({ params });
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.props.setTrailsFilters({ ...params, ...cleanPagination });
    this.getTrails({ ...params, ...cleanPagination }, true, true);
    this.setState({ company: '' });
  };

  cleanAdvancedFilters = () => {
    const cleanParams = {
      title: '',
      companyId: '',
      ...cleanPagination,
    };
    this.props.setTrailsFilters(null);
    this.setState({ company: '' });
    this.getTrails(cleanParams);
  };

  removeTrail (id) {
    this.props.removeTrail(id, () => {
      message.success(I18n.t('routes.panel.knowledgeTrails.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  }

  render () {
    const { Content } = Layout;
    const { params, companyList } = this.state;
    const { loading, me, trailsPaginated } = this.props;

    return (
      <>
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.knowledgeTrails.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form
                onSubmit={(event) =>
                  this.applyAdvancedFilters(event, this.state.params)
                }
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params?.title}
                      onChange={(val) => this.fieldChange('title', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.name')}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      label={I18n.t(
                        'routes.panel.questionDatabase.dataTable.filters.company',
                      )}
                      value={params?.companyId}
                      onChange={(val) => this.fieldChange('companyId', val)}
                      options={companyList}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className='panel__layout__content'>
          <div className='modules'>
            <Row className='d-flex justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={ModuleIcon} />
                  </span>
                  {I18n.t('routes.panel.knowledgeTrails.pageTitle')}
                </h3>
              </Col>
              <Col className='text-right'>
                {/*  {[ ProfileType.ADMIN ].includes(me?.profileType) && (
                  <>
                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportPdfButton')}
                      onClick={() => this.exportPdfData()}
                      disabled={loading > 0}
                    />
                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportAllPdfButton')}
                      onClick={() => this.exportPdfData(true)}
                      loading={this.state.exportingPfdDataLoading}
                      disabled={
                        loading > 0 || this.state.exportingPfdDataLoading
                      }
                    />
                    <Divider type='vertical' />
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportCsvButtonText')}
                      onClick={() => this.csvExport()}
                      disabled={loading > 0}
                    />

                    <Divider type='vertical' />
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportAllCsvButtonText')}
                      onClick={() => this.csvExport(true)}
                      loading={this.state.exportingDataLoading}
                      disabled={loading > 0 || this.state.exportingDataLoading}
                    />

                    <CSVLink
                      data={this.state.csvData}
                      filename={I18n.t(
                        'routes.panel.moduleDetails.csvFileName',
                      )}
                      ref={this.csvLink}
                      style={{ display: 'none' }}
                    >
                      {I18n.t('shared.exportCsvButtonText')}
                    </CSVLink>
                  </>
                )}
                */}
                <Divider type='vertical' />
                {!this.props.isCreating && (
                  <AdvancedButton
                    text={I18n.t('routes.panel.modules.addNewButtonText')}
                    href={`${I18n.t('routes.panel.knowledgeTrails.url')}add`}
                  />
                )}
              </Col>
            </Row>

            <Divider />
            <div ref={this.pdfLink}>
              <DataTable
                getMethod={(parameters) => this.getTrails(parameters, true)}
                data={trailsPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                showSizeChanger
                pageSizeOptions={[ '10', '20', '50', '100' ]}
                params={params}
                columns={[
                  {
                    width: '50px',
                    key: I18n.t(
                      'routes.panel.knowledgeTrails.dataTable.columns.cover.key',
                    ),
                    title: I18n.t(
                      'routes.panel.knowledgeTrails.dataTable.columns.cover.title',
                    ),
                    render: (value) => (
                      value
                        ? <Avatar src={value} shape='square' />
                        : '--'
                    ),
                  },
                  {
                    ellipsis: true,
                    key: I18n.t(
                      'routes.panel.knowledgeTrails.dataTable.columns.title.key',
                    ),
                    title: I18n.t(
                      'routes.panel.knowledgeTrails.dataTable.columns.title.title',
                    ),
                    render: (value) => value || '--',
                  },
                  {
                    ellipsis: true,
                    key: I18n.t(
                      'routes.panel.knowledgeTrails.dataTable.columns.company.key',
                    ),
                    title: I18n.t(
                      'routes.panel.knowledgeTrails.dataTable.columns.company.title',
                    ),
                    render: (value) => (value && value.name) || '--',
                  },
                  {
                    ellipsis: true,
                    key: I18n.t(
                      'routes.panel.knowledgeTrails.dataTable.columns.studentsAmount.key',
                    ),
                    title: I18n.t(
                      'routes.panel.knowledgeTrails.dataTable.columns.studentsAmount.title',
                    ),
                    render: (value) => value || '--',
                  },
                  {
                    ellipsis: true,
                    key: I18n.t(
                      'routes.panel.knowledgeTrails.dataTable.columns.isDraft.key',
                    ),
                    title: I18n.t(
                      'routes.panel.knowledgeTrails.dataTable.columns.isDraft.title',
                    ),
                    render: (value) => (value ? 'Sim' : 'Não'),
                  },
                  {
                    ellipsis: true,
                    key: I18n.t(
                      'routes.panel.knowledgeTrails.dataTable.columns.startAt.key',
                    ),
                    title: I18n.t(
                      'routes.panel.knowledgeTrails.dataTable.columns.startAt.title',
                    ),
                    render: (value) => <div>{DateUtils.humanizeDate(value, 'DD/MM/YYYY')}</div>,
                  },
                  {
                    width: '90px',
                    key: I18n.t(
                      'routes.panel.modules.knowledgeTrails.columns.actions.key',
                    ),
                    title: '',
                    render: (id) => (
                      <div
                        className='trail_tooltip'>
                        <Tooltip
                          title={I18n.t(
                            'routes.panel.modules.dataTable.columns.actions.goToEditText',
                          )}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type='link'
                            icon={<EditOutlined />}
                            onClick={() =>
                              navigate(
                                `${I18n.t(
                                  'routes.panel.knowledgeTrails.url',
                                )}details/${id}`,
                              )
                            }
                          />
                        </Tooltip>
                        {!this.props.isCreating &&
                          [ ProfileType.ADMIN, ProfileType.TEACHER ].includes(
                            this.props.me?.profileType,
                          ) && (
                          <Popconfirm
                            placement='left'
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.removeTrail(id)}
                          >
                            <Button type='link'
                              icon={<DeleteOutlined />} />
                          </Popconfirm>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  trailsFilter: FilterSelector.getTrailsFilter(state),
  trailsPaginated: TrailsSelectores.getTrailsPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTrailsPaginated: (parameters) =>
    dispatch(TrailsActions.getTrailsPaginated(parameters)),
  setTrailsFilters: (params) =>
    dispatch(FilterActions.setTrailsFilters(params)),
  removeTrail: (id, callback) =>
    dispatch(TrailsActions.removeTrail(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getCompanies: (callback) => dispatch(UtilsActions.getCompanies(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Trails);
