import { Col } from 'antd';
import React from 'react';

/**
 * @param {Object} props
 * @param {String} props.imageUri
 * @param {String} props.title
 * @param {String} props.description
 * @param {Number} props.progressPercent
 */
export const TrailPreview = ({
  description,
  imageUri,
  title,
}) => {
  return (
    <Col className='trail_preview_container'>
      <div className='first_row'>
        <img src={imageUri} />
        <Col className='texts_container'>
          <h4>{title}</h4>
          <p>{description}</p>
        </Col>
      </div>
    </Col>
  );
};
