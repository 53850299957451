import Compress from 'compress.js';

export const compressImages = async (image) => {
  let response = null;

  try {
    const compress = new Compress();

    response =  await compress.compress(image, {
      maxHeight: 1920,
      maxWidth: 1080,
      quality: 0.9,
      size: 1,
      resize: true,
    });

    response = response ? `${response[0].prefix}${response[0].data}` : null;
  } catch (error) {
    response = error;
  }

  return response;
};

export const getUniqueFilename = (extension) => {
  const timestamp = (new Date()).getTime();
  const random = Math.random().toString().substring(3, 13);

  return `${timestamp}_${random}${extension ? `.${extension}` : ''}`;
}

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};