import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_TRAIL_DETAILS,
  ACTION_SAVE_TRAIL_SEARCH,
  ACTION_SAVE_TRAILS_PAGINATED,
  ACTION_SAVE_USERS_PROGRESS,
} from '../actions/trails';

const initialState = Immutable({
  trailsPaginated: null,
  trailDetails: null,
  trailDetail: {},
  usersProgress: [],
});

export default function trail (
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_TRAILS_PAGINATED:
      state = {
        ...state,
        trailsPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_TRAIL_SEARCH:
      state = {
        ...state,
        trailDetails: action.payload,
      };
      return state;
    case ACTION_SAVE_TRAIL_DETAILS:
      state = {
        ...state,
        trailDetail: action.payload,
      };
      return state;
    case ACTION_SAVE_USERS_PROGRESS:
      state = {
        ...state,
        usersProgress: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getTrailsPaginated (state) {
  return state.trail.trailsPaginated;
}

export function getTrailDetails (state) {
  return state.trail.trailDetails;
}

export function getTrailDetail (state) {
  return state.trail.trailDetail;
}

export function getUsersProgress (state) {
  return state.trail.usersProgress;
}