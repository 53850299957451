import React, { forwardRef } from 'react';
import { Input, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const AdvancedInput = forwardRef(({
  label,
  placeholder,
  onChange,
  type,
  value,
  disabled,
  isPassword,
  isTextarea,
  name,
  required,
  maxLength,
  defaultValue,
  onKeyDown,
  style,
  onPressEnter,
  allowClear = true,
  tooltip
}, ref) => (
  <div
    className='advanced-input'
    style={style}
  >
    <label className='advanced-input__label'>
      <span className='advanced-input__label__inner'>
        {label}
        {!!tooltip && (
          <Tooltip
            title={tooltip}
          >
            <InfoCircleOutlined style={{ marginLeft: 5, alignItems: 'center' }} />
          </Tooltip>
        )}
      </span>
      {isPassword && (
        <Input.Password
          placeholder={placeholder}
          onChange={(ev) => onChange && onChange(ev.currentTarget.value)}
          type={type || 'text'}
          value={value}
          disabled={disabled || false}
          name={name || undefined}
          defaultValue={defaultValue}
          ref={ref}
        />
      )}
      {isTextarea && (
        <Input.TextArea
          placeholder={placeholder}
          onChange={(ev) => onChange && onChange(ev.currentTarget.value)}
          type={type || 'text'}
          value={value}
          disabled={disabled || false}
          autoSize={{ minRows: 4, maxRows: 10 }}
          allowClear={allowClear}
          name={name || undefined}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
          defaultValue={defaultValue}
          ref={ref}
        />
      )}
      {!isTextarea && !isPassword && (
        <Input
          placeholder={placeholder}
          onChange={(ev) => onChange && onChange(ev.currentTarget.value)}
          type={type || 'text'}
          value={value}
          disabled={disabled || false}
          name={name || undefined}
          maxLength={maxLength}
          required={required || undefined}
          defaultValue={defaultValue}
          onPressEnter={onPressEnter}
          ref={ref}
        />
      )}
    </label>
  </div>
));

AdvancedInput.displayName = 'AdvancedInput';
export default AdvancedInput;
