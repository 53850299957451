import { useMemo } from 'react'
import { contentFilters, examFilters, questionsFilters, TRAIL_OPTION } from '../../enum/trails';
import { I18n } from 'react-redux-i18n';

const headerTexts = {
  [ TRAIL_OPTION.AUDIO ]: () => I18n.t('routes.panel.knowledgeTrails.create.contents.filters.headers.podCast'),
  [ TRAIL_OPTION.QUESTION ]: () => I18n.t('routes.panel.knowledgeTrails.create.contents.filters.headers.question'),
  [ TRAIL_OPTION.RESUME ]: () => I18n.t('routes.panel.knowledgeTrails.create.contents.filters.headers.resume'),
  [ TRAIL_OPTION.VIDEO ]: () => I18n.t('routes.panel.knowledgeTrails.create.contents.filters.headers.video'),
  [ TRAIL_OPTION.EXAM ]: () => I18n.t('routes.panel.knowledgeTrails.create.contents.filters.headers.exam'),
}

export const useDynamicFiltersByType = (type) => {
  const { filters, headerText } = useMemo(() => {

    let filtersToUse = [];

    switch (type) {
      case TRAIL_OPTION.QUESTION:
        filtersToUse = questionsFilters;
        break;
      case TRAIL_OPTION.AUDIO:
      case TRAIL_OPTION.VIDEO:
      case TRAIL_OPTION.RESUME:
        filtersToUse = contentFilters;
        break;
      case TRAIL_OPTION.EXAM:
        filtersToUse = examFilters;
        break;
    }

    return {
      filters: filtersToUse,
      headerText: headerTexts[ type ]?.()
    }
  }, [ type ]);

  return { filters, headerText }
}