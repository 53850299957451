import { Col, Modal, Row } from 'antd'
import React, { useState } from 'react'
import {
  DeleteOutlined
} from '@ant-design/icons';

export const UsersModalList = ({ usersList, deleteItem }) => {
  const [ isModalOpen, setIsModalOpen ] = useState(false);

  return (
    <>
      {!!usersList.length && (
        <div
          onClick={() => setIsModalOpen(true)}
          className='users_count_button'>
          {usersList.length}
        </div>
      )}
      <Modal title="Basic Modal"
        visible={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        cancelText='Voltar'
      >
        <Col className='users_modal_content'>
          <h4>Alunos Atribuídos</h4>
          <Row className='list_row header_row'>
            <b className='field name'>Nome</b>
            <b className='divider_invisibvle'/>
            <b className='field'>Email</b>
          </Row>
          {usersList?.length ? usersList.map((user) => (
            <Row key={user.id}
              className='list_row'>
              <div className='field name'>{user.name}</div>
              <div className='divider'/>
              <div className='field'>{user.email}</div>
              <button className='delete_button'
                onClick={() => deleteItem(user)}>
                <DeleteOutlined />
              </button>
            </Row>
          )) : (
            <div className='empty_list'>Nenhum Aluno Atribuído</div>
          )}
        </Col>
      </Modal>
    </>
  )
}