import { Col, message, Row } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import AdvancedSelect from '../../shared/AdvancedSelect/AdvancedSelect'
import { I18n } from 'react-redux-i18n'
import { VerticalFiltersPanel } from '../../VerticalFiltersPanel/VerticalFiltersPanel'
import { ContentsListToAssign } from '../ContentsListToAssign/ContentsListToAssign'
import { ContentMediaType, TRAIL_OPTION, TRAIL_OPTION_LABEL } from '../../../app/enum/trails'
import { useDynamicFiltersByType } from '../../../app/hooks/trails/useDynamicFiltersByType'
import { cleanPagination } from '../../../app/utils/pagination'
import QuizApi from '../../../app/api/quiz'
import ContentApi from '../../../app/api/content'
import { VideoLinkSelector } from '../../VideoLinkSelector/VideoLinkSelector'

export const TrailCompositionItem = ({ type, defineItem, defaultValue}) => {
  const trailItemOptionsList = Object.values(TRAIL_OPTION).map(trailItemOption => ({
    id: trailItemOption,
    name: TRAIL_OPTION_LABEL[ trailItemOption ](),
  }));

  const [ trailItemType, setTrailItemType ] = useState(type)

  const [ appliedFilters, setAppliedFilters ] = useState({})
  const [ tableData, setTableData ] = useState()
  const [ loading, setLoading ] = useState(false);

  const { filters, headerText } = useDynamicFiltersByType(trailItemType);

  const getContentDetailsUrl = useCallback((id) => {
    switch (trailItemType) {
      case TRAIL_OPTION.QUESTION:
        return `${I18n.t('routes.panel.quiz.urlQuestion')}${id}`
      case TRAIL_OPTION.AUDIO:
      case TRAIL_OPTION.VIDEO:
      case TRAIL_OPTION.RESUME:
        return `${I18n.t('routes.panel.contentDetails.url')}${id}`
      case TRAIL_OPTION.EXAM:
        return `${I18n.t('routes.panel.quiz.urlQuiz')}${id}`
      default:
        return async () => null
    }
  }, [ trailItemType ])

  const fetchTableData = useMemo(() => {
    switch (trailItemType) {
      case TRAIL_OPTION.QUESTION:
        return QuizApi.getQuestionsPaginated
      case TRAIL_OPTION.AUDIO:
        return (params) => ContentApi.getContentsPaginated({ ...params, contentType: ContentMediaType.AUDIO })
      case TRAIL_OPTION.VIDEO:
        return (params) => ContentApi.getContentsPaginated({ ...params, contentType: ContentMediaType.VIDEO })
      case TRAIL_OPTION.RESUME:
        return (params) => ContentApi.getContentsPaginated({ ...params, contentType: ContentMediaType.RESUME })
      case TRAIL_OPTION.EXAM:
        return QuizApi.getQuizzesPaginated
      default:
        return async () => null
    }
  }, [ trailItemType ]);

  const findData = async (params) => {
    try {
      setLoading(true)
      const data = await fetchTableData(params);
      setTableData(data)
    } catch (err) {
      message('Erro ao buscar dados')
    } finally {
      setLoading(false)
    }
  }

  const handleFilter = async (params) => {
    const filtersToApply = {
      ...params,
      orderBy: 'createdAt',
      isDESC: true,
      ...cleanPagination,
    }
    setAppliedFilters(filtersToApply)
    findData(filtersToApply)
  };

  const handleGetDataByTable = async (params) => {
    const filtersToApply = {
      ...appliedFilters,
      ...params,
    }
    setAppliedFilters(filtersToApply)
    findData(filtersToApply)
  }

  const handleClear = () => {
    handleFilter({})
  }

  useEffect(() => {
    setTableData({ count: 0, rows: [] })
    setAppliedFilters(null)
  }, [ trailItemType, type ]);

  const onSelectRow = (selectedRow) => {
    const row = tableData?.rows.find(item => selectedRow[ 0 ] === item.id)

    const item = {
      id: row.id,
      type: trailItemType,
      title: row.title || row.description
    };

    defineItem(item)
  };

  return (
    <div className='trail_composition_item_container'>
      <Col>
        <AdvancedSelect
          defaultValue={defaultValue || undefined}
          options={trailItemOptionsList}
          label={I18n.t(
            'routes.panel.knowledgeTrails.create.contents.typeSelect.label',
          )}
          value={trailItemType}
          onChange={setTrailItemType}
        />
        {trailItemType && trailItemType !== TRAIL_OPTION.YOUTUBE && (
          <Row className='select_content_box'>
            <VerticalFiltersPanel
              headerLabel={headerText}
              fields={filters}
              onFilter={handleFilter}
              onClear={handleClear}
            />
            <ContentsListToAssign
              data={tableData}
              onDetails={(id) =>
                window.open(getContentDetailsUrl(id), '_blank').focus()
              }
              getData={handleGetDataByTable}
              appliedFilters={appliedFilters}
              loading={loading}
              onSelectRow={onSelectRow}
            />
          </Row>
        )}
        {trailItemType === TRAIL_OPTION.YOUTUBE && (
          <VideoLinkSelector onSelectVideo={defineItem} />
        )}
      </Col>
    </div>
  );
}