import React, { useEffect, useState } from 'react'
import { Col, Empty, Select } from 'antd';
import { I18n } from 'react-redux-i18n';

const SearchableInput = ({
  title,
  placeholder,
  onSearch,
  searchTimeout,
  onChange,
  onEndReached,
  data,
  loading,
  defaultValue,
  value,
  multiple = true,
}) => {
  const [ textToSearch, setTextToSearch ] = useState('')
  const [ mounted, setMounted ] = useState(false)

  useEffect(() => {
    if (onSearch) onSearch(textToSearch)

    setMounted(true)
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(onSearch && mounted) onSearch(textToSearch)
    }, searchTimeout || 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [ textToSearch ])

  const handleScroll = (event) => {
    const {target} = event

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (onEndReached) {
        onEndReached()
      }
    }
  }

  return (
    <Col>
      <div style={{ marginBottom: '5px' }}>
        {title}
      </div>
      <Select
        mode={multiple ? 'multiple' : undefined}
        style={{ width: '100%' }}
        showSearch
        placeholder={placeholder}
        showArrow
        filterOption={false}
        onSearch={setTextToSearch}
        onChange={onChange}
        onPopupScroll={handleScroll}
        notFoundContent={
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={I18n.t('shared.notFoundSearch')}
          />
        }
        allowClear
        loading={loading}
        defaultValue={defaultValue}
        value={value}
      >
        {
          data.length && data.map(option => (
            <Select.Option key={option.id}>{option.title}</Select.Option>
          ))
        }
      </Select>
    </Col>
  )
}

export default SearchableInput;