import { DeleteFilled, RightOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { TrailCompositionItem } from './TrailCompositionItem/TrailCompositionItem';
import { TrailDefinedItem } from './TrailDefinedItem/TrailDefinedItem';
import { TRAIL_OPTION_LABEL } from '../../app/enum/trails';

/**
 * @param {Object} props
 * @param {Function} props.defineItemContent
 * @param {Function} props.onRemove
 * @param {Object} props.content
 * @param {Boolean} props.canUndo
 */
export const TrailCompositionCollapsibleItem = ({
  onRemove,
  defineItemContent,
  content,
  canUndo,
  handleUndo,
  defaultValue,
}) => {
  const divRef = useRef();
  const [ opened, setOpened ] = useState(false);
  const [ , setRenderCount ] = useState(0);
  const [ definedItem, setDefinedItem ] = useState(content);

  const collapseHandleStyle = opened
    ? {
      collapseContainer: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      iconContent: {
        transform: 'rotate(90deg)',
      },
      collapsibleBox: {
        height: divRef.current?.clientHeight,
      },
    }
    : {
      collapseContainer: {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      },
      iconContent: {
        transform: 'rotate(0deg)',
      },
      collapsibleBox: {
        height: 0,
      },
    };

  useEffect(() => {
    if (!divRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      setRenderCount((prev) => prev + 1);
    });
    resizeObserver.observe(divRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const handleDefineItemContent = (item) => {
    setDefinedItem(item);
    defineItemContent(item);
  };

  useEffect(() => {
    if (content?.id !== definedItem?.id) {
      setDefinedItem(content);
    }
  }, [ content?.id ]);

  useEffect(() => {
    if (content?.title) {
      setDefinedItem(content);
    } else {
      setDefinedItem(undefined);
    }
  }, [ content ]);

  return (
    <div className='trail_composition_collapsible_item_container'>
      <Row
        className='collapse_topic_container'
        style={collapseHandleStyle.collapseContainer}
      >
        <div className='order_container'>
          <UnorderedListOutlined />
        </div>
        <Row
          className='collapse_topic_content'
          onClick={() => setOpened((prev) => !prev)}
        >
          <div
            className='arrow_icon_content'
            style={collapseHandleStyle.iconContent}
          >
            <RightOutlined />
          </div>
          <span>
            {`${
              content?.type
                ? TRAIL_OPTION_LABEL[content.type]()
                : 'Novo Conteúdo'
            }: ${content?.title || 'NÃO DEFINIDO'}`}
          </span>
        </Row>
        <div onClick={onRemove}
          className='delete_button'>
          <DeleteFilled />
        </div>
      </Row>
      <div
        style={collapseHandleStyle.collapsibleBox}
        className='collapsible_box'
      >
        <div ref={divRef}>
          {definedItem?.id || definedItem?.videoId ? (
            <TrailDefinedItem
              definedItem={definedItem}
              handleEdit={setDefinedItem}
              canUndo={canUndo}
              handleUndo={handleUndo}
            />
          ) : (
            <TrailCompositionItem
              type={content?.type}
              defineItem={handleDefineItemContent}
              defaultValue={defaultValue || undefined}
            />
          )}
        </div>
      </div>
    </div>
  );
};