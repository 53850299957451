/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import CourseApi from '../api/course'
import { CourseType } from '../enum/courseType'

const ITEMS_PER_PAGE = 100

export const useCoursesSelect = ({ companyIdToFilter, defaultCoursesList = {count: 0, rows: []} }) => {
  const [ coursesList, setCoursesList ] = useState(defaultCoursesList)
  const [ loadingCourses, setLoadingCourses ] = useState(false)
  const [ page, setPage ] = useState(0)
  const [ titleToSearch, setTitleToSearch ] = useState()

  const findCourses = async (reset, title) => {
    if (!reset && coursesList.count <= coursesList.rows.length) return;

    if (loadingCourses) return;

    setLoadingCourses(true)

    setTitleToSearch(title)

    try {
      const {count, rows} = await CourseApi.getCoursesPaginated({
        limit: ITEMS_PER_PAGE,
        offset: reset ? 0 : page,
        page: reset ? 1 : page + 1,
        companyId: companyIdToFilter,
        type: CourseType.COURSE,
        title
      })

      if (reset) {
        setPage(0)
        setCoursesList({count, rows});
        return
      }

      setPage(prev => prev + 1)
      setCoursesList(prev => ({count, rows: [ ...prev.rows, ...rows ]}))
    } catch (err) {
      //
    } finally {
      setLoadingCourses(false)
    }
  }

  useEffect(() => {
    findCourses(true)
  }, [ companyIdToFilter ]);

  const onSearchCourses = (title) => {
    findCourses(true, title)
  }

  const loadMoreCourses = () => {
    findCourses(false, titleToSearch)
  }

  return {coursesList, loadingCourses, onSearchCourses, loadMoreCourses}
}