import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import { SearchOutlined } from '@ant-design/icons';
import { CompanyAdvancedSelect } from '../../../../../components/shared/CompanyAdvancedSelect/CompanyAdvancedSelect';

export const CompanyFilterForm = ({ reset, onSubmit, defaultFilters }) => {
  const [ selectedCompany, setSelectedCompany ] = useState(defaultFilters?.company || null);

  useEffect(() => {
    setSelectedCompany(defaultFilters?.company || null);
  }, [ defaultFilters ]);

  const handleReset = () => {
    setSelectedCompany(null);
    reset();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ company: selectedCompany, page: 1, pageSize: 10 });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={10}>
        <Col span={24}>
          <CompanyAdvancedSelect
            setSelectedCompany={setSelectedCompany}
            selectedCompany={selectedCompany}
          />
        </Col>
      </Row>
      <Row justify="end"
        align="middle"
        className="advanced-filter__search-button text-right">
        <AdvancedButton
          type="link"
          text={I18n.t('shared.advancedFilters.clearButtonText')}
          onClick={handleReset}
        />
        <AdvancedButton
          htmlType="submit"
          text={I18n.t('shared.advancedFilters.filterButtonText')}
          icon={<SearchOutlined />}
        />
      </Row>
    </form>
  );
};
