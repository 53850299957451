import { Col, Row } from 'antd';
import React, { useState } from 'react';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import { I18n } from 'react-redux-i18n';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import { SearchOutlined } from '@ant-design/icons';
import { CompanyAdvancedSelect } from '../../../../../components/shared/CompanyAdvancedSelect/CompanyAdvancedSelect';
import { CourseSearchableInput } from '../../../../../components/shared/CourseSearchableInput/CourseSearchableInput';

export const AssignmentFilterForm = ({ reset, onSubmit, defaultFilters }) => {
  const [ fields, setFields ] = useState({
    name: defaultFilters?.name,
    email: defaultFilters?.email,
    company: defaultFilters?.company,
    course: defaultFilters?.course,
  });

  const onFieldChange = (field, value) => {
    setFields((prevFields) => ({ ...prevFields, [ field ]: value }));
  };

  const handleReset = () => {
    setFields({});
    reset();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(fields);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={10}>
        <Col span={12}>
          <AdvancedInput
            name='name'
            placeholder={I18n.t('shared.type')}
            label={I18n.t('shared.advancedFilters.form.name')}
            onChange={(val) => onFieldChange('name', val)}
            value={fields.name}
          />
        </Col>
        <Col span={12}>
          <AdvancedInput
            name='email'
            placeholder={I18n.t('shared.type')}
            label={I18n.t('shared.advancedFilters.form.email')}
            onChange={(val) => onFieldChange('email', val)}
            value={fields.email}
          />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <CompanyAdvancedSelect
            setSelectedCompany={(val) => onFieldChange('company', val)}
            selectedCompany={fields.company}
          />
        </Col>
        <Col span={12}>
          <CourseSearchableInput
            selectedCompany={fields.company?.id}
            setSelectedCourse={(val) => onFieldChange('course', val)}
            selectedCourse={fields.course}
          />
        </Col>
      </Row>
      <Row
        justify='end'
        align='middle'
        className='advanced-filter__search-button text-right'
      >
        <AdvancedButton
          type='link'
          text={I18n.t('shared.advancedFilters.clearButtonText')}
          onClick={handleReset}
        />
        <AdvancedButton
          htmlType='submit'
          text={I18n.t('shared.advancedFilters.filterButtonText')}
          icon={<SearchOutlined />}
        />
      </Row>
    </form>
  );
};
